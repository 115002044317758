// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see ttps://fresco-docs.netlify.com/#/carousel/background-video

// CR-56
$(".appealListingPage .donorsListWrapper").appendTo('.appealListingPage .listing');


$(document).ready(function () {
  $(".homefeaturecategory-homepageheropost").insertBefore('.homeFeatures');
  $(".homefeaturecategory-homeboximpactstats").insertBefore('.homeFeatures');
  $(".homeIntroWrapper").insertBefore('.homefeaturecategory-homeboximpactstats');
  $(".homepage .carousel").insertBefore('.homefeaturecategory-homeboxquickgiving');
});